

































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyModerateWealthExitPollViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/strategy-moderate-wealth-exit-poll-view-model';

@Component({ name: 'StrategyModerateWealthExitPoll' })
export default class StrategyModerateWealthExitPoll extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  strategy_moderate_wealth_exit_poll_view_model = Vue.observable(
    new StrategyModerateWealthExitPollViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  async sendAnswer() {
    const sent = await this.strategy_moderate_wealth_exit_poll_view_model.sendSurvey();
    if (sent) {
      this.closeModal();
    }
  }

  created() {
    this.strategy_moderate_wealth_exit_poll_view_model.initialized();
  }
}
