import TYPES from '@/types';

import { v4 } from 'uuid';

// Application
import SearchAnswersInitialPollQuery
  from '@/modules/flagship/contact-points/sowos-moderate/application/queries/search-answers-initial-poll-query';
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';
import CreateResponseNotReadyToContractCommand
  from '@/modules/flagship/contact-points/sowos-moderate/application/commands/create-response-not-ready-to-contract-command';

// Domain
import { PollResultEntity }
  from '@/modules/flagship/contact-points/sowos-moderate/domain/entities/poll-result-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StrategyModerateWealthExitPollViewModel {
  @Inject(TYPES.SEARCH_ANSWERS_INITIAL_POLL_QUERY)
  private readonly get_answers_initial_poll!: SearchAnswersInitialPollQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  private readonly get_investment_product_fund_type!: GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.CREATE_RESPONSE_NOT_READY_TO_CONTRACT_MODERATE_COMMAND)
  private readonly create_response_not_ready_contract!: CreateResponseNotReadyToContractCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-moderate-wealth-exit-poll';

  is_loading = false;

  survey_selected: PollResultEntity = {
    id: '',
    poll_status_id: '',
    label: '',
    description: '',
    name: '',
  };

  id = v4();

  investment_product_fund_type_id = '';

  answers: Array<PollResultEntity> = [];

  get send_survey_disabled() {
    return !this.survey_selected.id || !this.investment_product_fund_type_id || this.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  sendSurvey = async (): Promise<boolean> => {
    this.is_loading = true;
    try {
      const response_survey = {
        id: this.id,
        investment_product_fund_type_id: this.investment_product_fund_type_id,
        poll_status_id: this.survey_selected.poll_status_id,
        poll_result_id: this.survey_selected.id,
      };

      await this.create_response_not_ready_contract.execute(response_survey);
      this.message_notifier.showSuccessNotification(this.translate('messages.create_response_survey'));
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_response_survey'));
      return false;
    } finally {
      this.is_loading = false;
    }
  }

  loadAnswers = async () => {
    try {
      this.answers = await this.get_answers_initial_poll
        .execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_answers'));
    }
  }

  loadInvestmentProductFundTypes = async () => {
    try {
      const investment_product_fund_types = await this.get_investment_product_fund_type.execute();
      const moderate_investment_product_fund_type = investment_product_fund_types.find(
        (fund_type) => fund_type.name === 'SWS-MODERADO',
      );
      if (moderate_investment_product_fund_type) {
        this.investment_product_fund_type_id = moderate_investment_product_fund_type.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_answers'));
    }
  }

  initialized = async () => {
    this.is_loading = true;
    await this.loadAnswers();
    await this.loadInvestmentProductFundTypes();
    this.is_loading = false;
  }
}
